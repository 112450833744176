import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Html5QrcodeScanner } from 'html5-qrcode';
import './App.css'

function QRScanner({ history }) {
  const navigate = useNavigate();
  let scanner;

  useEffect(() => {
    if(!scanner?.getState()){

    scanner = new Html5QrcodeScanner('reader', {
      fps: 10,
      aspectRatio: 1,
      qrbox: 250,
    });
  
    scanner.render(success, error);
  
    function success(data) {
      scanner.clear();

      if (data.startsWith("https://yobo.megamindtech.com/chat?model=")) {
        let model = data.split('?model=')[1];
        console.log(model);
        navigate(`./chat?model=${model}`);
      }
      else {
        navigate(data);
      }
    }
  
    function error(err) {
      console.error(err);
    }
  }

  }, []);

  return (
    <div className='QRApp'>
      <div className='qrback'>
        <h1 className='qrh1'>QR Scanner</h1>
        <div id="reader"></div>
      </div>
    </div>
  );
}

export default QRScanner;
