import { useState, useEffect } from 'react'
import { OpenAI } from 'openai'
import '../App.css'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { ChatContainer, MessageList, Message, MessageInput, TypingIndicator, ConversationHeader } from '@chatscope/chat-ui-kit-react';


let openaiApiKey = "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";

const openai = new OpenAI({
    apiKey: openaiApiKey,
    dangerouslyAllowBrowser: true 
});

const userId = 'user_' + Date.now() + '_' + Math.random().toString(36).substr(2, 9);

async function art_recommendation() {
    try {
        const response = await fetch('./art_recommendation');
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // console.log(data);
        return data;
    } catch (error) {
        console.error('Error fetching art recommendations:', error);
    }
}

async function artist_recommendation() {
    try {
        const response = await fetch('./artist_recommendation');
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // console.log(data);
        return data;
    } catch (error) {
        console.error('Error fetching artist recommendations:', error);
    }
}

async function art_description(Art_Name) {
    try {
        const response = await fetch('/art_description', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ art_name: Art_Name })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data);
        return data;
    } catch (error) {
        console.error('Error fetching art description:', error);
    }
}

async function art_characteristics(Art_Name) {
    try {
        const response = await fetch('/art_characteristics', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ art_name: Art_Name })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data);
        return data;
    } catch (error) {
        console.error('Error fetching art characteristics:', error);
    }
}

async function art_artist_name(Art_Name) {
    try {
        const response = await fetch('/art_artist_name', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ art_name: Art_Name })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data);
        return data;
    } catch (error) {
        console.error('Error fetching art artist name:', error);
    }
}


async function art_price(Art_Name) {
    try {
        const response = await fetch('/art_price', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ art_name: Art_Name })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data);
        return data;
    } catch (error) {
        console.error('Error fetching art price:', error);
    }
}


async function artist_art_suggestion(Artist_Name) {
    try {
        const response = await fetch('/artist_art_suggestion', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ artist_name: Artist_Name })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data);
        return data;
    } catch (error) {
        console.error('Error fetching artist art suggestions:', error);
    }
}


let systemMessage = JSON.parse(`[
    {"role": "system", "content": "Your name is 'MegaMind', a responsive customer service bot at Abbozzo Gallery, an art gallery in Toronto, Ontario, Canada. MegaMind is a product of MegaMind AI, a company located in Brampton, Ontario, specializing in AI-powered workplace assistants. MegaMind is designed to offer brief, concise responses, with each answer limited to 35 words or less. The primary goal is to foster engaging conversations, encouraging users to ask more questions about Abbozzo Gallery. MegaMind refrains from mentioning GPT, ChatGPT, or OpenAI, focusing solely on its identity as a customer service bot. For any inquiries unrelated to Abbozzo Gallery or MegaMind, provide prompt, accurate responses.You should evaluate the validity of the user's questions and ask for clarification if a question is unclear. In case of repeated questions, reiterate responses concisely based on prior interaction and knowledge."},
    {"role": "user", "content": "Who founded Abbozzo Gallery?"},{"role": "assistant", "content": "Ineke Zigrossi founded Abbozzo Gallery in 1993."},
    {"role": "user", "content": "Who is the gallery manager at Abbozzo Gallery?"},{"role": "assistant", "content": "Blake Zigrossi is the gallery manager at Abbozzo Gallery."},
    {"role": "user", "content": "Who manages media at Abbozzo Gallery?"},{"role": "assistant", "content": "Quincy Shaw is the media manager at Abbozzo Gallery."},
    {"role": "user", "content": "Who is the assistant curator at Abbozzo Gallery?"},{"role": "assistant", "content": "Hannah Scott serves as the assistant curator at Abbozzo Gallery."},
    {"role": "user", "content": "What is Hannah Scott's role at Abbozzo Gallery?"},{"role": "assistant", "content": "Hannah Scott is the assistant curator at Abbozzo Gallery."},
    {"role": "user", "content": "What is Quincy Shaw's role at Abbozzo Gallery?"},{"role": "assistant", "content": "Quincy Shaw is the media manager at Abbozzo Gallery."},
    {"role": "user", "content": "Who is Ineke Zigrossi?"},{"role": "assistant", "content": "Ineke Zigrossi is the founder and director of Abbozzo Gallery."},
    {"role": "user", "content": "Who is Blake Zigrossi?"},{"role": "assistant", "content": "Blake Zigrossi is the gallery manager at Abbozzo Gallery."},
    {"role": "user", "content": "Who are the key team members at Abbozzo Gallery?"},{"role": "assistant", "content": "The team includes Ineke Zigrossi, Blake Zigrossi, Quincy Shaw, and Hannah Scott."},
    {"role": "user", "content": "Can you summarize the story of Abbozzo Gallery?"},{"role": "assistant", "content": "Abbozzo Gallery, founded in 1993 by Ineke Zigrossi, moved to 401 Richmond in Toronto after two decades, offering diverse art in a vibrant arts hub"},
    {"role": "user", "content": "It was nice talking with you"},{"role": "user", "content": "Thank you! Let me know if I can help with anything else."}
    ]`);
let message_history = systemMessage || [];

let functions = [
    {
        type: "function",
        function: {
        name: "get_art_description",
        description: "Retrieve the description of the mentioned art and provide an art curator's perspective on the artwork",
        parameters: {
            type: "object",
            properties: {
                art_name: {
                    type: "string",
                    description: "Name of the Art. For example: POULTRY NO. 5, RED ROSES",
                }
            },
            required: ["art_name"],
        },
    }
}, {
    type: "function",
    function:  {
        name: "get_art_characteristics",
        description: "Retrieve the name of the art and provide information about its composition, dimensions, and print type. For example: 1) Can you tell me the characteristics of this art? 2) What is the art made of? 3) Tell me about the properties of the art?",
        parameters: {
            type: "object",
            properties: {
                art_name: {
                    type: "string",
                    description: "Name of the Art. For example: POULTRY NO. 5, RED ROSES",
                }
            },
            required: ["art_name"],
        },
    }}, {
        type: "function",
        function: {
        name: "get_art_recommendation",
        description: "Recommend and suggest various types of art to people. Examples of user prompt 1) Tell me about some art 2) Show me some arts 3) Can you tell me about some of the arts",
        parameters: {
            type: "object",
            properties: {
                art_name: {
                    type: "string",
                    description: "Name of the Art. For example: POULTRY NO. 5, RED ROSES",
                }
            }
        },
    }}, {
        type: "function",
        function: {
        name: "get_artist_recommendation",
        description: "Recommend and suggest various Artist. For example: 1) Suggest me some artists? 2) Tell me about an artist here? 3) Name an artist at Abbozzo 4) Tell me about some art creators here",
        parameters: {
            type: "object",
            properties: {
                artist_name: {
                    type: "string",
                    description: "Name of the Artist",
                }
            }
        },
    }}, {
        type: "function",
        function: {
        name: "get_art_price",
        description: "Retrieve the price of the Art or Painting. For example: What is the price of the art work? Tell me the price of this art?",
        parameters: {
            type: "object",
            properties: {
                art_name: {
                    type: "string",
                    description: "Name of the Art. For example: POULTRY NO. 5, RED ROSES",
                }
            }
        },
        required: ["art_name"],
    }}, {
        type: "function",
        function: {
        name: "get_artist_name",
        description: "Retrieve the name of the Artist who made or drew or painted the Art or Painting",
        parameters: {
            type: "object",
            properties: {
                art_name: {
                    type: "string",
                    description: "Name of the Art. For example: POULTRY NO. 5, RED ROSES",
                }
            }
        },
        required: ["art_name"],
    }}, {
        type: "function",
        function: {
        name: "get_arts_of_artist",
        description: "Suggest the Art, Paintings or the works using the Artist name from user question.",
        parameters: {
            type: "object",
            properties: {
                artist_name: {
                    type: "string",
                    description: "First Name of the Artist.For example: What are the arts or painting done by Alex? Result: artist_name = Alex",
                }
            }
        },
        required: ["artist_name"],
    }},
  ];

function Chat() {
    const [messages, setMessages] = useState([{
      message: "Hello, How can I help you?",
      sentTime: "just now",
      sender: "YOBO"
    }]);
    const [isTyping, setIsTyping] = useState(false);
  
    let model = "ft:gpt-3.5-turbo-1106:megamind-tech:abzo-v2:8R2Ufm3L";
  
    const [dimensions, setDimensions] = useState({
      height: window.innerHeight,
      width: window.innerWidth
    });
  
    useEffect(() => {
      function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        });
      }
      window.addEventListener("resize", handleResize);
    });
  
    const handleSend = async (message) => {
        const newMessage = {
          message,
          direction: "outgoing",
          sender: "user"
        };
    
        const newMessages = [...messages, newMessage];
        
        setMessages(newMessages);
        setIsTyping(true);
        await processMessage(newMessages);
    };

    async function predict(chatMessages) {

        let session_id = "asda3423";
        let apiMessages = chatMessages.map((messageObject) => {
            let role = "";
            if (messageObject.sender === "user") {
              role = "user";
            } else {
              role = "assistant";
            }
            return { role: role, content: messageObject.message}
        });

        try {
            // console.log(systemMessage.concat(apiMessages));
            const response = await openai.chat.completions.create({
                model: model,
                max_tokens: 128,
                messages: systemMessage.concat(apiMessages),
                user: session_id,
                tools: functions,
                tool_choice: "auto",
            });
            const responseMessage = response.choices[0].message;
            // console.log(responseMessage);
            const toolCalls = responseMessage.tool_calls;
            // console.log(toolCalls);
        
            if (responseMessage.tool_calls) {
                const availableFunctions = {
                    get_art_description: art_description,
                    get_art_recommendation: art_recommendation,
                    get_art_characteristics: art_characteristics,
                    get_arts_of_artist: artist_art_suggestion,
                    get_art_price: art_price,
                    get_artist_name: art_artist_name,
                    get_artist_recommendation: artist_recommendation
                };
                // message_history.push(responseMessage);
                apiMessages = apiMessages.concat(responseMessage);
                for (const toolCall of toolCalls) {
                  const functionName = toolCall.function.name;
                  const functionToCall = availableFunctions[functionName];
                  const functionArgs = JSON.parse(toolCall.function.arguments);
                //   console.log(functionArgs.art_name, functionArgs.artist_name);
                  
                  const functionResponse = JSON.stringify(
                    await functionToCall(
                        (functionName === "get_arts_of_artist") ? functionArgs.artist_name : functionArgs.art_name
                    )
                  )
            
                  if (functionResponse) {
                    apiMessages = apiMessages.concat({
                        tool_call_id: toolCall.id,
                        role: "tool",
                        name: functionName,
                        content: functionResponse,
                    });
                    // console.log(apiMessages);
                    let secondResponse = await openai.chat.completions.create({
                    model: model,
                    messages: apiMessages,
                    });
                    return secondResponse.choices[0].message.content.split('\n')[0];
                  }
                  else {
                    let secondResponse = await openai.chat.completions.create({
                        model: model,
                        messages: apiMessages,
                        user: session_id,
                    });
                    return secondResponse.choices[0].message.content.split('\n')[0];
                  }
                }   
              }
              else {
                let answer = responseMessage.content.split('\n')[0];
                apiMessages = apiMessages.concat({
                    'role': 'assistant',
                    'content': answer
                })
                return answer;
              }
          }
        catch (error) {
            // return "Sorry, can you repeat that?";
            const response2 = await openai.chat.completions.create({
                model: model,
                max_tokens: 128,
                messages: message_history
              });
            const responseMessage2 = response2.choices[0].message;
            let answer2 = responseMessage2.content.split('\n')[0];
            apiMessages = apiMessages.concat({
                'role': 'assistant',
                'content': answer2
            })
            return answer2;
        }
    }
    
    async function processMessage(chatMessages) {
        const abbozzoResponse = await predict(chatMessages);
        setMessages([...chatMessages, {
            message: abbozzoResponse,
            sender: "YOBO"
        }])
        setIsTyping(false);
    }
  
  
    const URLify = (message, direction) => {
        const urlRegex = /(\b(https?:\/\/)?[a-zA-Z0-9-]+\.[a-zA-Z-]+(\.[a-zA-Z-]+)*(\/[a-zA-Z-._~:/?#[\]@!$&'()*+,;=]*)?\b)/g;
        return message.replace(urlRegex, (url) => {
          // check if the protocol is present, if not, prepend http://
          const completeUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
          if (direction === "outgoing") {
            return `<a class="outTextURL" href="${completeUrl}" target="_blank">${url}</a>`;
          }
          return `<a class="inTextURL" href="${completeUrl}" target="_blank">${url}</a>`;
        });
      };

      const saveChatLog = async (chatMessages) => {
        try {
          await fetch('https://yobo.megamindtech.com/logger/saveChat.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId: userId, messages: chatMessages })
          });
        } catch (error) {
          console.error('Error saving chat log:', error);
        }
      };
    
      // useEffect hook to save the chat log every time a new message is added
      useEffect(() => {
        if (messages.length > 0) {
          saveChatLog(messages);
        }
      }, [messages]);
  
  
  

    return (
        <ChatContainer className="App" style={{ height: dimensions.height}}>
              <ConversationHeader>
                <ConversationHeader.Content>
                  <div>
                    <img src="./logo.png" alt="logo" style={{ width: "auto", height: "50px" }} />
                    <p><span>AI</span></p>
                  </div>
                </ConversationHeader.Content>
              </ConversationHeader>
              <MessageList 
                scrollBehavior="auto" 
                typingIndicator={isTyping ? <TypingIndicator content="YOBO is typing" /> : null}
              >
                {messages.map((message, i) => (
                <Message key={i} model={{ ...message, message: URLify(message.message, message.direction) }} />
              ))}
              </MessageList>
              <MessageInput placeholder="Type message here" onSend={handleSend} attachButton={false}/>
            </ChatContainer>
    );
}

export default Chat;