import React from 'react';
import './App.css';
import AppRouter from './AppRouter';

// Main App component - serves as the root and implements routing via AppRouter
function App() {
  return <AppRouter />;
}

export default App;
