import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import QRScanner from './QRScanner';
import Chat from './Chat';
import Martello from './clients/Martello';
import Abbozzo from './clients/Abbozzo';

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<QRScanner />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/martello" element={<Martello />} />
        <Route path="/abbozzo" element={<Abbozzo />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
